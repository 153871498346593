import React, { useState, useEffect } from 'react'
import SecondFooter from '../layouts/SecondFooter'
import SecondHeader from '../layouts/SecondHeader'
import { GetProfile, UserLogin } from "../API/AuthApi";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert"; 
import GoogleLogin from 'react-google-login';
import FacebookLogin from "react-facebook-login";
import { SingalRegister } from "../API/AuthApi";
import { GetAllNotification } from '../API/NotificationApi';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';
export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setpasswordError] = useState("");
    const [loginError, setLoginError] = useState("");
    const [hidden, setHidden] = useState(true);
    const history = useHistory();

    useEffect(
      function ()  {
        const button = document.getElementsByClassName('GOOGLE')[0];
        button.innerHTML = '<span style="text-transform:none;"><Link  className="d-inline-block py-2 px-4 border round"><img src="assets/image/google.png" alt="" class="align-middle pe-3" />Google </Link> </span>';       
      
        const elem = document.getElementsByClassName('GOOGLE')[0].style;
        elem.removeProperty('box-shadow')

        const Facebutton = document.getElementsByClassName('kep-login-facebook')[0];
        Facebutton.innerHTML = '<span style="color:black; font-size: 14px; font-weight:100; text-transform:none;"><Link  className="d-inline-block py-2 px-4 border round"><img src="assets/image/fb.png" alt="" class="align-middle pe-3" />Facebook </Link> </span>';       
      
       
    
    });

    const responseGoogle = (response) => {
        const googleResponse = response.profileObj;
        const formData = new FormData();
        formData.append("email", googleResponse.email);
        formData.append("name", googleResponse.givenName);
        formData.append("surname", googleResponse.familyName);
        formData.append("media_login_type", "google");
        formData.append("media_login_id", googleResponse.googleId);
        formData.append("password", "google");
        formData.append("password_confirmation", "google");
        
        
        SingalRegister(formData).then((resp)=>{

            if(resp.data.type === "success"){
            window.localStorage.setItem('token', resp.data.access_token);
            window.localStorage.setItem('role', resp.data.role_id);
            window.localStorage.setItem('userProfile', resp?.data?.roleWiseProfileData?.user_image);
            window.localStorage.setItem('login_type', resp.data.login_type);
            if (resp.data.role_id === 5) {
                let data = {
                    id: resp?.data?.userOtherData?.id,
                    image: resp?.data?.roleWiseProfileData?.user_image,
                    name: resp?.data?.userOtherData?.name,
                    surname: resp?.data?.userOtherData?.surname,
                    profile: {
                        occupation: resp?.data?.roleWiseProfileData?.occupation
                    }
                }
                window.localStorage.setItem('user', JSON.stringify(data));
                // swal("Success", "Login Successfully", "success");
                history.push('/')
            }else if(resp.data.role_id === 6){
                console.log("role 6");
               let data = {
                    id: resp?.data?.userOtherData?.id,
                    image: resp?.data?.roleWiseProfileData?.user_image,
                    name: resp?.data?.userOtherData?.name,
                    surname: resp?.data?.userOtherData?.surname,
                    profile: {
                        occupation: resp?.data?.roleWiseProfileData.agency_profile.agency_name
                    }
                }
            
            window.localStorage.setItem('user', JSON.stringify(data));
         
            history.push('/dashboard')
            }else if(resp.data.role_id === 3){
                console.log("role 3");
                let data = {
                     id: resp?.data?.userOtherData?.id,
                     image: resp?.data?.roleWiseProfileData?.user_image,
                     name: resp?.data?.userOtherData?.name,
                     surname: resp?.data?.userOtherData?.surname,
                     profile: {
                         occupation: resp?.data?.roleWiseProfileData.agency_profile.agency_name
                     }
                 }
             
             window.localStorage.setItem('user', JSON.stringify(data));
           
             history.push('/dashboard')
             }
        }else{
            swal("Error", resp.data.message, "error");
        }
        });
        
      };

      

    const responseGoogleError = (response) =>{
        console.log(response);
    };
    const responseFacebook = (facebookResponse) =>{
        console.log(facebookResponse);
        const formData = new FormData();
        formData.append("email", facebookResponse.email);
        formData.append("name", facebookResponse.name);
        formData.append("surname", '');
        formData.append("media_login_type", "facebook");
        formData.append("media_login_id", facebookResponse.id);
        formData.append("password", "facebook");
        formData.append("password_confirmation", "facebook");
        
        
        SingalRegister(formData).then((resp)=>{

            if(resp.data.type === "success"){
            window.localStorage.setItem('token', resp.data.access_token);
            window.localStorage.setItem('role', resp.data.role_id);
            window.localStorage.setItem('userProfile', resp?.data?.roleWiseProfileData?.user_image);
            window.localStorage.setItem('login_type', resp.data.login_type);
            if (resp.data.role_id === 5) {
                let data = {
                    id: resp?.data?.userOtherData?.id,
                    image: resp?.data?.roleWiseProfileData?.user_image,
                    name: resp?.data?.userOtherData?.name,
                    surname: resp?.data?.userOtherData?.surname,
                    profile: {
                        occupation: resp?.data?.roleWiseProfileData?.occupation
                    }
                }
                window.localStorage.setItem('user', JSON.stringify(data));
                // swal("Success", "Login Successfully", "success");
                history.push('/')
            }else if(resp.data.role_id === 6){
                console.log("role 6");
                let data = {
                     id: resp?.data?.userOtherData?.id,
                     image: resp?.data?.roleWiseProfileData?.user_image,
                     name: resp?.data?.userOtherData?.name,
                     surname: resp?.data?.userOtherData?.surname,
                     profile: {
                         occupation: resp?.data?.roleWiseProfileData.agency_profile.agency_name
                     }
                 }
                 window.localStorage.setItem('user', JSON.stringify(data));
            
             // swal("Success", "Login Successfully", "success");
             history.push('/dashboard')
             }else if(resp.data.role_id === 3){
                console.log("role 3");
                 let data = {
                      id: resp?.data?.userOtherData?.id,
                      image: resp?.data?.roleWiseProfileData?.user_image,
                      name: resp?.data?.userOtherData?.name,
                      surname: resp?.data?.userOtherData?.surname,
                      profile: {
                          occupation: resp?.data?.roleWiseProfileData.agency_profile.agency_name
                      }
                  }
              
             window.localStorage.setItem('user', JSON.stringify(data));
            
              history.push('/dashboard')
            }
        }else{
            swal("Error", resp.data.message, "error");
        }
        });
        
    };
    const componentClicked = () =>{
        console.log("response");
    };
    const handlelogin = async (event) => {
        event.preventDefault();
        setLoginError("");
        if (!email) {
            setEmailError("Please enter your email address");
        }

        if (!password) {
            setpasswordError("Please enter your password");
        }

        if (email && password) {
            try {
                let data = { email, password }
                UserLogin(data).then((resp) => {
                    let dt = {
                        user_id : resp?.data?.userOtherData?.id
                    };
                    // let tkn = '';
                    GetProfile(dt).then((item)=>{
                        console.log('item__',JSON.stringify(item,null,4));
                        let login_type = item?.data?.data.facebook_id ? 'facebook' : item?.data?.data?.google_id ? 'google' : 'normal';
                        window.localStorage.setItem('login_type',login_type);
                    }).catch((e)=>{

                    })
                    // window.localStorage.setItem('login_type',resp.data);
                    GetAllNotification(resp.data.access_token).then((res)=>{
                        let notifications = res.data.total_unread_notification;
                        localStorage.setItem('unread_notifications',notifications);
                        window.dispatchEvent(new Event("storage"));
                    });
                    if (resp.data.status === "true") {
                        window.localStorage.setItem('token', resp.data.access_token);
                        window.localStorage.setItem('role', resp.data.role_id);
                        window.localStorage.setItem('userProfile', resp?.data?.roleWiseProfileData?.user_image);
                        window.localStorage.setItem('login_type', resp.data.login_type);

                        if (resp.data.role_id === 5) {
                            let data = {
                                id: resp?.data?.userOtherData?.id,
                                image: resp?.data?.roleWiseProfileData?.user_image,
                                name: resp?.data?.userOtherData?.name,
                                surname: resp?.data?.userOtherData?.surname,
                                profile: {
                                    occupation: resp?.data?.roleWiseProfileData?.occupation
                                }
                            }
                            window.localStorage.setItem('user', JSON.stringify(data));
                            // swal("Success", "Login Successfully", "success");
                            history.push('/')
                        } else {
                            let data = "";
                            if (resp.data.role_id === 2) {

                                data = {
                                    id: resp?.data?.userOtherData?.id,
                                    image: resp?.data?.roleWiseProfileData?.user_image,
                                    name: resp?.data?.userOtherData?.name,
                                    surname: resp?.data?.userOtherData?.surname,
                                    profile: {
                                        occupation: resp?.data?.roleWiseProfileData.agency_name
                                    }
                                }

                            } else {
                                data = {
                                    id: resp?.data?.userOtherData?.id,
                                    image: resp?.data?.roleWiseProfileData?.user_image,
                                    name: resp?.data?.userOtherData?.name,
                                    surname: resp?.data?.userOtherData?.surname,
                                    profile: {
                                        occupation: resp?.data?.roleWiseProfileData.agency_profile.agency_name
                                    }
                                }
                            }
                            window.localStorage.setItem('userApproveStatus', resp?.data?.roleWiseProfileData?.is_approved);
                            window.localStorage.setItem('user', JSON.stringify(data));
                            window.localStorage.setItem('login_type', resp.data.login_type);
                            // swal("Success", "Login Successfully", "success");
                            history.push('/dashboard')

                        }
                    } else {
                        swal("error", resp.data.message, "error");
                    }
                });
            } catch (err) {
                setpasswordError("");
                setEmailError("");
                if (err) {
                    setLoginError(
                        "Either your email or password is not correct. Please insert your correct login details"
                    );
                }
            }
        }
    };

   

  

    

    return (
        <div className="wrapper  login-wrapper">
            <SecondHeader />
            <div className="main bg-transparent border-0">
                <div className="container pt-4 ">
                    <div className="row justify-content-center">
                        <div className="col-md-5 ">
                            <div className="forgot-pwd p-4 text-center bg-white">
                                <h3 className="h3 bold pb-5">Log In</h3>
                                <form className="forgot-step pb-5" onSubmit={handlelogin}>
                                    <div className="form-field text-start pb-4">
                                        <label for="" className="d-block pb-2 medium">Email</label>
                                        <input type="email"
                                            className="w-100"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                             />
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {emailerror}
                                        </p>
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {loginError}
                                        </p>
                                    </div>
                                    <div className="form-field text-start pb-4">
                                        <label for="" className="d-block pb-2 medium">Password</label>
                                        <div className='d-flex align-items-center' style={{position : 'relative'}}>
                                        <input type={hidden ? "password" : "text"}
                                            className="w-100"
                                            value={password}
                                            style={{paddingRight : 40}}
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            // hidden={hidden}
                                             />
                                        <div style={{
                                            cursor : 'pointer', 
                                            position : 'absolute', 
                                            right : 10
                                        }} onClick={()=>setHidden(!hidden)}>
                                            {
                                                hidden ? 
                                                <AiFillEyeInvisible size={25}/>
                                                :
                                                <AiFillEye size={25}/>
                                            }
                                        </div>
                                        </div>
                                        
                                        <p style={{ color: "red", margintop: "-10px" }}>
                                            {passworderror}
                                        </p>
                                        <Link to="/forgot-password" className="medium text-primary text-end fs-14 pt-2 d-block">Forgot Password?</Link>
                                    </div>

                                    <button type="submit" className="btn-md">Login</button>
                                </form>
                                {/* <AIIcon */}
                                <p className="small text-center medium text-gray">Continue With</p>
                                <div className="d-flex justify-content-around align-items-center px-lg-4 pb-3 social-btn">
                                   
                                    


                                        
                                          

                                            <FacebookLogin
                                                appId={process.env.REACT_APP_FACEBOOK_ID}
                                                // autoLoad={false}
                                                fields="name,email,picture"
                                                buttonText="Facebook"
                                                // className="GOOGLE"
                                                onClick={componentClicked}
                                                callback={responseFacebook}
                                                icon="fa-facebook"
                                                />
                                   
                                          &nbsp;
                                         <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_ID}
                                            buttonText="LOGIN WITH GOOGLE"
                                            className="GOOGLE"
                                            style={{
                                                boxShadow: 'none',
                                            }}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogleError}
                                            // isSignedIn={true}
                                            
                                        >
                                        
                                        </GoogleLogin> 
                                        
                                       
                                    

                                </div>
                            </div>
                            <div className="text-center py-5">
                                {/* <p className="medium">Don’t have an account? <Link to="/register" className="text-primary bold">Sign Up</Link></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondFooter />
        </div>
    )
}
