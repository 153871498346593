import React, { useState, useEffect } from 'react'
import SecondFooter from '../layouts/SecondFooter'
import SecondHeader from '../layouts/SecondHeader'
import { SingalRegister } from "../API/AuthApi";
import { useHistory, Link } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import FacebookLogin from "react-facebook-login";
import swal from "sweetalert";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export default function Register() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password_confirmation, setPasswordConfirmation] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [city, setCity] = useState("")
    const [occupation, setOccupation] = useState("")
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("")
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setpasswordError] = useState("");
    const [password_confirmationerror, setPasswordConfirmationError] = useState("")
    const [nameerror, setNameError] = useState("")
    const [cityerror, setCityError] = useState("")
    const [phoneerror, setPhoneError] = useState("")
    const [occupationerror, setOccupationError] = useState("")
    const [password_hidden, setPasswordHidden] = useState(true);
    const [confirm_password_hidden, setConfirmPasswordHidden] = useState(true);

    const history = useHistory();

    const handleImage = (e) => {
        setImage({
            fileBytes: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0]),
        });
    };

    useEffect(
        function ()  {
          const button = document.getElementsByClassName('GOOGLE')[0];
          button.innerHTML = '<span style="text-transform:none;"><Link  className="d-inline-block py-2 px-4 border round"><img src="assets/image/google.png" alt="" class="align-middle pe-3" />Google </Link> </span>';       
        
          const elem = document.getElementsByClassName('GOOGLE')[0].style;
          elem.removeProperty('box-shadow')
  
          const Facebutton = document.getElementsByClassName('kep-login-facebook')[0];
          Facebutton.innerHTML = '<span style="color:black; font-size: 14px; font-weight:100; text-transform:none;"><Link  className="d-inline-block py-2 px-4 border round"><img src="assets/image/fb.png" alt="" class="align-middle pe-3" />Facebook </Link> </span>';       
        
         
      
      });
  
      const responseGoogle = (response) => {
        const googleResponse = response.profileObj;
        const formData = new FormData();
        formData.append("email", googleResponse.email);
        formData.append("name", googleResponse.givenName);
        formData.append("surname", googleResponse.familyName);
        formData.append("media_login_type", "google");
        formData.append("media_login_id", googleResponse.googleId);
        formData.append("password", "google");
        formData.append("password_confirmation", "google");
        
        
        SingalRegister(formData).then((resp)=>{

            if(resp.data.type === "success"){
            window.localStorage.setItem('token', resp.data.access_token);
            window.localStorage.setItem('role', resp.data.role_id);
            window.localStorage.setItem('userProfile', resp?.data?.roleWiseProfileData?.user_image);

            if (resp.data.role_id === 5) {
                let data = {
                    id: resp?.data?.userOtherData?.id,
                    image: resp?.data?.roleWiseProfileData?.user_image,
                    name: resp?.data?.userOtherData?.name,
                    surname: resp?.data?.userOtherData?.surname,
                    profile: {
                        occupation: resp?.data?.roleWiseProfileData?.occupation
                    }
                }
                window.localStorage.setItem('user', JSON.stringify(data));
                // swal("Success", "Login Successfully", "success");
                history.push('/')
            }
        }else{
            swal("Error", resp.data.message, "error");
        }
        });
        
      };

      

    const responseGoogleError = (response) =>{
        console.log(response);
    };
    const responseFacebook = (googleResponse) =>{
        const formData = new FormData();
        formData.append("email", googleResponse.email);
        formData.append("name", googleResponse.name);
        formData.append("surname", '');
        formData.append("media_login_type", "facebook");
        formData.append("media_login_id", googleResponse.id);
        formData.append("password", "facebook");
        formData.append("password_confirmation", "facebook");
        
        
        SingalRegister(formData).then((resp)=>{

            if(resp.data.type === "success"){
            window.localStorage.setItem('token', resp.data.access_token);
            window.localStorage.setItem('role', resp.data.role_id);
            window.localStorage.setItem('userProfile', resp?.data?.roleWiseProfileData?.user_image);

            if (resp.data.role_id === 5) {
                let data = {
                    id: resp?.data?.userOtherData?.id,
                    image: resp?.data?.roleWiseProfileData?.user_image,
                    name: resp?.data?.userOtherData?.name,
                    surname: resp?.data?.userOtherData?.surname,
                    profile: {
                        occupation: resp?.data?.roleWiseProfileData?.occupation
                    }
                }
                window.localStorage.setItem('user', JSON.stringify(data));
                // swal("Success", "Login Successfully", "success");
                history.push('/')
            }
        }else{
            swal("Error", resp.data.message, "error");
        }
        });
        
    };
    const componentClicked = () =>{
        console.log("response");
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email) {
            setEmailError("Please enter your email address");
            setLoading(false);
        }

        if (!password) {
            setpasswordError("Please enter your password");
            setLoading(false);
        }
        if (!phone) {
            setPhoneError("Please enter your phone number");
            setLoading(false);
        }

        
        if (!password_confirmation) {
            setPasswordConfirmationError("Please enter your confirm password");
            setLoading(false);
        }
        if (password !== password_confirmation) {
            setPasswordConfirmationError("Password and confirm password don't match");
            setLoading(false);
        }

        if (!name) {
            setNameError("Please enter your first name");
            setLoading(false);
        }
        if (!city) {
            setCityError("Please enter your city");
            setLoading(false);
        }
        if (!occupation) {
            setOccupationError("Please enter your occupation");
            setLoading(false);
        }
        if (email && password && password_confirmation && phone && name && city && occupation && password === password_confirmation) {
             
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            formData.append("password_confirmation", password_confirmation);
            formData.append("name", name);
            formData.append("surname", surname);
            formData.append("phone", phone);
            formData.append("city", city);
            formData.append("occupation", occupation);
            formData.append("description", description);
            if(image){
                formData.append("image", image.fileBytes);
            }
            SingalRegister(formData).then((res)=>{
                setLoading(false);
                if(res.status === 200){
                swal("Success", res.data.message, "success");
                history.push('/login')   
                }
                if(res.status === 201){
                    if(res.data.message.email){
                    swal("Error", res.data.message.email[0], "error");
                    }else{
                        swal("Error", "somethin went wrong", "error");  
                    }
                }
            });

           
        }




    };

    return (
        <>
            <div class="wrapper  login-wrapper">


                <SecondHeader />

                <div class="main bg-transparent border-0">
                    <div class="container pt-4 ">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 ">
                                <div class="signup-form p-4 text-center bg-white">
                                    <h3 class="h3 bold pb-4">Sign Up</h3>

                                    <p class="small text-center medium text-gray">With</p>
                                    <div class="d-flex justify-content-around align-items-center px-lg-4 pb-3 social-btn">
                                        {/* <a href="#" class="d-inline-block py-2 px-4 border round"><img src="assets/image/fb.png" alt="" class="align-middle pe-3" />Facebook</a>
                                        <a href="#" class="d-inline-block py-2 px-4 border round">
                                            <img src="assets/image/google.png" alt="" class="align-middle pe-3" />Google</a> */}
                                         <FacebookLogin
                                                appId={process.env.REACT_APP_FACEBOOK_ID}
                                                // autoLoad={false}
                                                fields="name,email,picture"
                                                buttonText="Facebook"
                                            
                                                onClick={componentClicked}
                                                callback={responseFacebook}
                                                icon="fa-facebook"
                                                />
                                   
                                          &nbsp;
                                         <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_ID}
                                            buttonText="LOGIN WITH GOOGLE"
                                            className="GOOGLE"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogleError}
                                            // isSignedIn={true}
                                            
                                        >
                                        
                                        </GoogleLogin> 
                                        
                                    </div>
                                    <p class="small text-center medium text-gray">or Continue With</p>


                                    <form onSubmit={handleSubmit} class="site-form text-start">

                                        <div class="row pb-4">
                                            <div class="form-field col-md-12">
                                                <label class="d-block medium fs-15">Email</label>
                                                <input type="email"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={email}
                                                    onChange={(e) => { setEmail(e.target.value) }}
                                                     
                                                    />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {emailerror}
                                                </p>
                                            </div>

                                            <div class="form-field col-md-12">
                                                <label class="d-block medium fs-15">Phone Number</label>
                                                <input type="number"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={phone}
                                                    onChange={(e) => { setPhone(e.target.value) }}
                                                     
                                                    />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {phoneerror}
                                                </p>
                                            </div>


                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">Password</label>

                                                <div className='d-flex align-items-center' style={{position : 'relative'}}>
                                                <input type={password_hidden ? "password" : "text"}
                                                    class="w-100"
                                                    placeholder=""
                                                    value={password}
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                    style={{paddingRight : 40}}
                                                />

                                                <div style={{
                                                    cursor : 'pointer', 
                                                    position : 'absolute', 
                                                    right : 10
                                                }} 
                                                onClick={()=>setPasswordHidden(!password_hidden)}
                                                >
                                                    {
                                                        password_hidden ? 
                                                        <AiFillEyeInvisible size={25}/>
                                                        :
                                                        <AiFillEye size={25}/>
                                                    }
                                                </div>
                                                </div>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {passworderror}
                                                </p>
                                            </div>
                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">Confirm Password</label>
                                                <div className='d-flex align-items-center' style={{position : 'relative'}}>
                                                <input type={confirm_password_hidden ? "password" : "text"}
                                                    class="w-100"
                                                    placeholder=""
                                                    value={password_confirmation}
                                                    onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                                                    style={{paddingRight : 40}}
                                                    
                                                />
                                                <div style={{
                                                    cursor : 'pointer', 
                                                    position : 'absolute', 
                                                    right : 10
                                                }} 
                                                onClick={()=>setConfirmPasswordHidden(!confirm_password_hidden)}
                                                >
                                                    {
                                                        confirm_password_hidden ? 
                                                        <AiFillEyeInvisible size={25}/>
                                                        :
                                                        <AiFillEye size={25}/>
                                                    }
                                                </div>
                                                </div>
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {password_confirmationerror}
                                                </p>
                                            </div>


                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">Name</label>
                                                <input type="text"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(e) => { setName(e.target.value) }}
                                                    
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {nameerror}
                                                </p>
                                            </div>

                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">Surname</label>
                                                <input type="text"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={surname}
                                                    onChange={(e) => { setSurname(e.target.value) }}
                                                    
                                                />
                                            </div>

                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">City</label>
                                                <input type="text"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={city}
                                                    onChange={(e) => { setCity(e.target.value) }}
                                                    
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {cityerror}
                                                </p>
                                            </div>

                                            <div class="form-field col-md-6">
                                                <label class="d-block medium fs-15">Occupation</label>
                                                <input type="text"
                                                    class="w-100"
                                                    placeholder=""
                                                    value={occupation}
                                                    onChange={(e) => { setOccupation(e.target.value) }}
                                                    
                                                />
                                                <p style={{ color: "red", margintop: "-10px" }}>
                                                    {occupationerror}
                                                </p>
                                            </div>


                                            <div class="form-field col-md-12">
                                                <label class="d-block medium fs-15">How would you describe yourself?(Optional)</label>
                                                <textarea name=""
                                                    id=""
                                                    value={description}
                                                    onChange={(e) => { setDescription(e.target.value) }}
                                                   
                                                ></textarea>
                                            </div>
                                            
                                            <div class="form-field col-md-12 singup-photo">
                                                <input type="file" id="sign-profilepic" onChange={(e) => handleImage(e)} />
                                                <label for="sign-profilepic" class="d-flex align-items-center">
                                                    {
                                                        image.fileUrl ? 
                                                        <span>
                                                        <img
                                                            src={image.fileUrl}
                                                            height="170px"
                                                            width="100%"
                                                            className="image-priview"
                                                            
                                                        />
                                                        </span>
                                                        :
                                                        <span><i class="far fa-image"></i></span>
                                                    }
                                                    Add Profile<br /> Photo
                                                </label>
                                                {/* {image.fileUrl ? (
                                                    <img
                                                        src={image.fileUrl}
                                                        height="170px"
                                                        width="100%"
                                                        className="mt-2"
                                                    />
                                                ) : (
                                                    ""
                                                )} */}
                                            </div>
                                            <div class="form-field col-md-12">
                                                <h6>
                                                    By signing up, you are giving us consent with your personal information and you
                                                    acknowledge that you have read and accepted our Privacy Policy.
                                                </h6>
                                            </div>

                                        </div>

                                        <div class="text-center">
                                            <button class="btn-md" type="submit">Sign Up</button>
                                        </div>

                                    </form>
                                </div>

                                <div class="text-center py-5">
                                    <p class="medium">Already have an account? <Link to="/login" class="text-primary bold">Log In</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SecondFooter />


            </div>
        </>
    )
}
